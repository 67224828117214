<!--
 * @Description: 
 * @Author: xiawenlong
 * @Date: 2022-01-14 08:43:09
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-01-14 17:10:05
-->
<template>
  <div class="add_credentials">
    <el-card>
      <div slot="header" class="clearfix">
        <span class="title">{{ id ? '编辑' : '发布' }}公告</span>
      </div>
      <el-form ref="form" :model="form" label-width="120px" :rules="rules">
        <el-form-item label="公告主题：" prop="title">
          <el-input
            v-model="form.title"
            placeholder="请输入28个字内的公告标题"
            style="width:360px"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item ref="attachmentList" label="公告附件：" prop="attachmentList">
          <upload-file
            ref="uploadFile"
            v-model="form.attachmentList"
            :file-type="'.pdf,.doc,.docx'"
            :limit="5"
          ></upload-file>
          <p class="uploadFileText">大小限制5MB内，支持 .pdf.doc.docx格式，限制5个文件</p>
        </el-form-item>
        <el-form-item label="公告摘要：" prop="introduction">
          <el-input
            v-model="form.introduction"
            placeholder="请输入64个字内的公告摘要"
            clearable
            style="width:100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="公告内容：" prop="content">
          <tinymce ref="editor" v-model="form.content"></tinymce>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit()">{{ id ? '编辑' : '发布' }}公告</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import Tinymce from '@/components/Tinymce'
import UploadFile from '@/components/UploadFile'
import {
  classCertAnnouncementAdd,
  classCertAnnouncementDetail,
  classCertAnnouncementEdit,
} from '@/api/certificate'
import to from 'await-to'
export default {
  components: {
    UploadFile,
    Tinymce,
  },
  data() {
    return {
      id: '',
      form: {
        attachmentList: [],
      },
      info: {},
      loading: false,
      rules: {
        title: [
          { required: true, message: '请输入28个字内的公告标题', trigger: 'blur' },
          { min: 1, max: 28, message: '请输入28个字内的公告标题', trigger: 'blur' },
        ],
        introduction: [
          { required: true, message: '请输入64个字内的公告摘要', trigger: 'blur' },
          { min: 1, max: 64, message: '请输入64个字内的公告摘要', trigger: 'blur' },
        ],
        content: [{ required: true, message: '请输入公告内容', trigger: 'blur' }],
      },
    }
  },
  mounted() {
    this.classCertId = this.$route.query.classCertId
    if (this.$route.params.id) {
      this.id = this.$route.params.id
      this.getInfo()
    }
  },
  methods: {
    async getInfo() {
      const [res, err] = await to(classCertAnnouncementDetail({ id: this.id }))
      if (err) return this.$message.warning(err.msg)
      this.form = res.data
      this.form.attachmentList = res.data.attachmentDtoList.map(item => ({
        ...item,
        docName: item.attachmentName,
      }))
    },
    async submit() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          this.loading = true
          this.form.attachmentList = this.form.attachmentList.map(item => ({
            ...item,
            attachmentName: item.name || item.attachmentName,
          }))
          if (this.id) {
            this.form.id = this.id
            const [, err] = await to(
              classCertAnnouncementEdit({ ...this.form, classCertId: this.classCertId }),
            )
            this.loading = false
            if (err) return this.$message.error(err.msg)
            this.$message.success('编辑成功')
          } else {
            const [, err] = await to(
              classCertAnnouncementAdd({ ...this.form, classCertId: this.classCertId }),
            )
            this.loading = false
            if (err) return this.$message.error(err.msg)
            this.$message.success('新增成功')
          }
          this.$router.push(`/certificate/trainingManage/notice?classCertId=${this.classCertId}`)
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.uploadFileText {
  color: #909399;
  font-size: 12px;
}
</style>
